<template>
    <page-content title="上下线记录">

        <common-table
                ref="table"
                path="web/iot/device/online"
                date-range
                :queryParams="queryParams"
                :columns="columns">

            <template slot="search">
<!--                <a-button @click="$refs.table.export('报警记录')">导出Excel</a-button>-->
            </template>

            <template slot="online" slot-scope="{record}">
                <a-tag v-if="record.online" color="green">上线</a-tag>
                <a-tag v-else  color="#CCC">下线</a-tag>
            </template>

        </common-table>


    </page-content>
</template>
<script>
    export default {

        data () {
            return {
                queryParams:{
                    deviceId:this.$route.params.deviceId
                }
            }
        },

        computed: {
            columns () {
                return [
                    {
                        title: '时间',
                        dataIndex: 'createdAt',
                    },
                    {
                        title: '上下线',
                        dataIndex: 'online',
                        scopedSlots: {customRender: 'online'},
                    },
                ]
            }
        },
        methods: {
            getList () {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

</style>
